.pick-game-variants-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  p {
    margin-bottom: 0;
    font-size: 14px;
  }
  .checkbox-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .ant-checkbox-wrapper {
      margin-left: 0 !important;
      margin: 5px 0;
    }
    .ant-checkbox-disabled + span {
      color: #13c2c2;
    }
  }
}
.pickModal {
  .ant-modal-content {
    .ant-modal-body {
    }
  }
}
