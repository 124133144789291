.dark-theme .notifications-list-container {
  background-color: #232930;
  color: #e0e0e0;
  .notification-header {
    border-bottom: 2px solid #e8e8e8;
    .notification-header-text {
      font-size: 12px;
      color: #e0e0e0;
    }
  }
  .notification-list {
    max-height: 50vh;
    overflow-y: auto;
    .ant-list-item {
      &.unread {
        background: #f78c1b66;
      }
      border: none;
      padding: 0 20px;
      border-bottom: 1px solid #e8e8e8;
      position: relative;
      cursor: pointer;
      .ant-list-item-meta {
        padding: 16px 20px 16px 0;
        width: 300px;
      }
      .ant-list-item-meta-description {
        padding-bottom: 10px;
        position: relative;
      }
      .ant-list-item-meta-avatar {
        margin-top: 20px;
      }
      .datetime {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 12px;
      }
    }
  }
  .delete-button {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
