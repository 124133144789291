@keyframes blinkBorder {
  0% {
    border-color: #61ad7b;
  }
  50% {
    border-color: #8bae97;
  }
  100% {
    border-color: #61ad7b;
  }
}

.dark-theme .event-card.ant-card {
  height: 200px;
  background-color: #323d46;
  border-radius: 0 !important;
  border-width: 1px;
  border-style: solid;
  .ant-card-body {
    padding: 0 !important;
  }
  .event-publish {
    font-size: 14px;
    font-weight: 600;
    height: 23px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 5px;
  }
  &.event-status-upcoming {
    border-color: #f18b2d;
    .gx-widget-badge {
      background-color: #f18b2d;
    }
    .event-publish {
      background-color: #f18b2d;
    }
  }
  &.event-status-running {
    border-color: #61ad7b;
    animation: blinkBorder 0.7s infinite;
    .gx-widget-badge {
      background-color: #61ad7b;
    }
    .event-publish {
      background-color: #61ad7b;
    }
  }
  &.event-status-finished {
    border-color: #819299;
    .gx-widget-badge {
      background-color: #819299;
    }
    .event-publish {
      background-color: #819299;
    }
  }
  &.event-no-published {
    opacity: 0.5;
  }
  .event-cover {
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .event-banner {
      width: 100%;
      height: 250px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }
  .ant-card-body {
    padding: 10px;
    .gx-widget-badge {
      right: -1px;
      top: -1px;
    }
  }
}
.draft-tag {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateY(-30%) translateX(-50%) rotate(10deg);
  border-top: 5px dashed #ffffff;
  border-bottom: 5px dashed #ffffff;
  font-size: 40px;
  font-weight: bolder;
}
