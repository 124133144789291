.offeredGameList {
  ul.ant-list-items {
    li.ant-list-item {
      &.hasNoVariable {
        //height: 35px;
      }
      &.hasVariable {
        padding-bottom: 10px;
        .game-size-wrapper {
          padding-top: 0 !important;
          height: 30px;
        }
        .ant-list-item-action {
          li {
            button {
              position: relative;
              bottom: -18px;
            }
          }
        }
      }
    }
  }
}
