//Colors
//$primary: #4d8dcc;
//$secondary: #2b2b2b;
//$secondaryLight: #434652;
//$darkGrey: #1d222e;
//$grey: #fbfbfb;
//$greyMiddleLight: #c4c4c4;
//$greyLight: #dedede;
//$greyDisable: #8d8d8d;
//$greyEmptyState: #819299;
//
//$accent1: #36d1cf;
//$accent2: #ba57ce;
//$accent3: #4d8dcc;
//$desaturatedAccent1: #aae4e4;
//$desaturatedAccent2: #cc90d8;
//$background1: #f7f8fd;
//$Background2: #e9f5f5;
//$mutedPrimary: #8a9ba8;
//$menuBar: #0e1011;
//$error: #fe5f55;
//$warning: #f18b2d;
@import 'flop-2.0/style/Color';

.text-secondaryLight {
  color: $secondaryLight !important;
}

.text-greyDisable {
  color: $greyDisable !important;
}

.text-greyEmptyState {
  color: $greyEmptyState !important;
}

.text-primary {
  color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.border-green {
  border-color: $green !important;
}

.crop-text-2 {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 40px;
  width: 200px;
  overflow: clip;
  justify-content: center;
  align-items: center;
}
