@import '../style/Color';

.addition-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 32px;
  margin: 2px;
  font-size: 14px;
  text-align: center;
  padding: 0 12px;
  border-radius: 16px;
  color: #fff;
  background-color: $accent3;
  border-color: $accent3;
  p {
    margin-bottom: 0;
    margin-right: 5px;
  }
}
