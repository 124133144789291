//@import 'Color';
@import 'custom';

.full-display {
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
  &-header {
    background-color: white;
    color: $secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &-body {
    .carousel-body {
      height: 100%;
      .carousel-slider {
        height: 100%;
        background: linear-gradient(180deg, #4c656a 0%, #34414a 100%);
      }
      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding-top: 16px;
        .available-seats {
          width: 25%;
          height: 100%;
        }
        .full-seats {
          width: 50%;
          display: flex;
          flex-direction: row;
        }
        .pending-seats {
          width: 25%;
          display: flex;
          flex-direction: row;
        }
      }
    }
    .slider-container {
      width: 100%;
      height: 640px;

      /* Add this */
      position: fixed;
      top: 0;
      left: 0;
    }
    .carousel {
      .slide {
        background: none;
      }
    }
  }
  .full-display-button {
    background: transparent;
    border: none;
    position: fixed;
    bottom: 75px;
    right: 28px;
  }
  .full-display-show-interest-list {
    position: fixed;
    bottom: 20px;
    left: 28px;
  }
  .default-player-icon {
    font-size: 35px;
    color: $blue5;
    opacity: 0.2;
    margin-left: 1px;
    margin-top: 6px;
  }
  .image-wrapper {
    overflow: hidden;
    display: inline-block;
    height: 35px;
    width: 35px;
    border-radius: 50%;

    img {
      object-fit: cover;
      -o-object-fit: cover;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
