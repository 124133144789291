@import 'custom';

.dealer-page-wrapper {
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
  .dealer-page-header {
    color: $secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .dealer-page-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 0 0 0;
    .poker-table {
      position: relative;
      width: 60%;
      max-width: 800px;
      aspect-ratio: 2 / 1;
      background: radial-gradient(circle, #3d3d3d, #212322);
      border-radius: 40%;
      //overflow: hidden;
    }
    .poker-table::before {
      content: '';
      position: absolute;
      top: 5%;
      left: 2.5%;
      width: 95%;
      height: 90%;
      background: #a5704b;
      border-radius: 40%;
    }
    .poker-table::after {
      content: '';
      position: absolute;
      top: 10%;
      left: 5%;
      width: 90%;
      height: 80%;
      background: radial-gradient(circle, #008000, #004d00);
      border-radius: 40%;
      box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
    }
    .table-center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      z-index: 10;
      width: 50%;
      text-align: center;
    }
    .chair-wrapper {
      position: absolute;
      text-align: center;
      .chair {
        width: 50px;
        height: 50px;
        background: #323c46;
        border: 1px solid #fff;
        border-radius: 50%;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .player-name {
        z-index: 10;
        position: relative;
        bottom: -2px;
        background-color: #252b2f;
        text-align: center;
        font-weight: bolder;
        border-radius: 2px;
        padding: 4px;
        width: max-content;
      }
    }
  }
}

@media (max-width: 600px) {
  .poker-table {
    width: 95%;
  }
  .logo {
    max-width: 30%;
  }
}

.bell-icon-read {
  color: orange;
  animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
  50% {
    opacity: 0.5;
  }
}
