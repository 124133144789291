.dark-theme {
  .table-card.gx-card-widget {
    height: 155px;
    background-color: #323d46;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    padding: 0 !important;
    .gx-widget-badge {
      background-color: #508cca;
      color: #ffffff;
      border-radius: 10px 0;
      left: -3px;
      right: auto;
    }
    .action-container {
      position: absolute;
      top: 0;
      right: 0;
    }
    .max-players-status {
      padding: 0 7px;
      position: absolute;
      left: 0;
      bottom: 5px;
    }
  }
}
