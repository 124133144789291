.popover {
  position: relative;
  width: 400px;
}

.noticeButton {
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}
.icon {
  padding: 4px;
  vertical-align: middle;
}

.badge {
  box-shadow: none;
  font-size: 11px;
  margin-right: 28px;
}

.tabs {
  :global {
    .ant-tabs-nav-scroll {
      text-align: center;
    }
    .ant-tabs-bar {
      margin-bottom: 0;
    }
  }
}

@primary-color: #4D8DCC;@form-vertical-label-padding: 0;