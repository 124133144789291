@import '../../custom';
$card-width: 300px;
$card-padding: 22px;

.card {
  display: flex;
  flex-direction: column;
  margin: 5px;
  border-radius: 8px;
  overflow: hidden;
  width: $card-width;
  min-width: $card-width;
  &-header {
    width: 100%;
    height: 140px;
    color: #000000;
    &.bg-success {
      background-color: #53e1a6 !important;
    }
    &.bg-warning {
      background-color: #ffde59 !important;
    }
    &.bg-danger {
      background-color: #f48038 !important;
    }
    &.bg-primary-light {
      color: #160486;
      background-color: #6baaf4 !important;
    }
    p {
      color: white;
      font-size: 20px;
      margin-bottom: 0;
      font-weight: 600;
    }
    .ant-progress-text {
      color: #000000 !important;
    }
  }
  &-tables {
    width: 100%;
    height: 47px;
    color: #000000;
    &.game-status-success {
      background-color: #1fb677;
    }
    &.game-status-warning {
      background-color: #eebf00;
    }
    &.game-status-danger {
      background-color: #c5520a;
    }
    &.game-status-primary-light {
      background-color: #1071e2;
    }
    p {
      font-size: 15px;
      margin-bottom: 0;
      font-weight: 400;
    }
  }
  &-body {
    width: 100%;
    height: 450px;
    padding: $card-padding;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    p {
      color: $font-primary;
      font-weight: 600;
    }
  }
  &-status {
    width: 100%;
    height: 50px;
    display: grid;
    background-color: #f4f7f9;
    p {
      margin: auto;
      color: $blue-3;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

.ant-list.card-waiting-list-items .ant-empty-description {
  color: #c0c0c1;
}
