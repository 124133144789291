.react-tel-input {
  $text-color-light: #e0e0e0;
  $text-color-dark: #545454;
  $contrast-background-color: rgba(255, 255, 255, 0.1);
  $background-color: #38424b;
  .flag-dropdown,
  .selected-flag:hover,
  .selected-flag:focus,
  .selected-flag.open {
    background-color: $contrast-background-color !important;
    border-color: $contrast-background-color !important;
    color: $text-color-light;
  }

  .country-list {
    color: $text-color-light;
    background-color: $background-color !important;
  }
  .country,
  .dial-code {
    color: $text-color-light !important;
  }
  .country-list .country:hover,
  .country-list .country.highlight {
    background-color: $contrast-background-color !important;
    border-color: $contrast-background-color !important;
    color: $text-color-light;
  }

  .country-list li {
    padding: 5px 12px;
    color: $text-color-dark;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
}
