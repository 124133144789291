.timer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  &.status-warning .time-wrapper.normal-size > span {
    background-color: #fa8c16b3;
    padding: 5px 4px;
  }
  &.status-danger .time-wrapper.normal-size > span {
    background-color: #f44336b3;
    padding: 5px 4px;
  }
  &.status-normal .time-wrapper.normal-size > span {
    background-color: #343d45;
    padding: 5px 4px;
  }
  .time-wrapper {
    display: flex;
    flex-direction: row;
    &.normal-size {
      span {
        font-size: 14px;
      }
    }
    &.small-size {
      span {
        font-size: 11px;
        font-weight: bold;
      }
    }
    span {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex: 0 1 25%;
      -ms-flex: 0 1 25%;
      flex: 0 1 25%;
      border-radius: 5px;
      &:first-child {
        margin-right: 1px;
      }
    }
  }
}

.timer-warning {
  color: red !important;
  animation: blink-animation 2s infinite;
}

@keyframes blink-animation {
  50% {
    opacity: 0;
  }
}
