@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBold';
  src: url('Roboto-Bold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoBlack';
  src: url('Roboto-Black.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
