@import 'custom';

.mobile-page-wrapper {
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
  .mobile-page-header {
    color: $secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-page-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 0 0 0;
    .action-button {
      background: #434f59;
      height: 100px;
      width: 100px;
      border-radius: 10px;
      box-shadow: 0 6px 10px -3px #546370;
      -webkit-box-shadow: 0 6px 10px -3px #546370;
    }
  }
}
