.editor {
    box-sizing: border-box;
    margin-bottom: 60px;
    height: 80px;
    .DraftEditor-editorContainer {
        background-color: rgba(250, 250, 250, 0.1) !important;
        border: 1px solid rgba(250, 250, 250, 0.1);
        border-radius: 2px 2px 0 0;
        padding: 8px 11px;
    }
    & :global(.public-DraftEditor-content) {
        height: 60px;
    }
}



.headlineButtonWrapper {
    display: inline-block;
}

.s6m29i4 {
    margin-top: 5px !important;
}

.e183m4hm .e8k2yoa, .e183m4hm .e13wqaj6{
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
    line-height: 0;
    font-size: 26px;
    color: #ffff;
    margin-left: 6px;
}
.e183m4hm .e13wqaj6{
    color: #6a9cc9 !important;
}

.e183m4hm .ejr02pv {
    background: #38424b;
    box-shadow: initial;
}

.e8k2yoa:hover, .e8k2yoa:active, .e8k2yoa:focus, .e13wqaj6:hover, .e13wqaj6:active, .e13wqaj6:focus {
    background: transparent !important;
    color: #6a9cc9;
}



.headlineButton {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
}

.headlineButton:hover,
.headlineButton:focus {
    background: #f3f3f3;
}
@primary-color: #4D8DCC;@form-vertical-label-padding: 0;