.waiting-list-card .ant-card-body {
  padding: 0;
  margin-top: 24px;
  height: calc(100vh - 348px);
  overflow-y: scroll;
  .waiting-list-collapse.ant-collapse {
    .waiting-list-panel.ant-collapse-item {
      .ant-list-item {
        border: none !important;
        padding: 6px 0 !important;
      }
    }
  }
}
