.table-setting-modal {
  .ant-modal-body {
    position: relative;
    button.delete-table {
      position: absolute;
      bottom: -47px;
      svg {
        margin-right: 5px !important;
      }
    }
  }
}
