.password-length {
  min-width: 300px;
}

.strength {
  width: 50px;
  height: 4px;
  margin-right: 2px;
  margin-top: 0px;
}

.strength-empty .progress-bar {
  background-color: #dedede !important;
}

.strength0 .progress-bar,
.strength1 .progress-bar {
  background-color: #e84141 !important;
}

.strength2 .progress-bar {
  background-color: #ffa14a !important;
}

.strength3 .progress-bar {
  background-color: #fde400 !important;
}

.strength4 .progress-bar {
  background-color: #43b980 !important;
}

.label {
  font-size: 12px;
}
