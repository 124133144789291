$primary: #323f48;
$secondary: #2b2b2b;
$secondaryLight: #434652;
$darkGrey: #1d222e;
$grey: #fbfbfb;
$greyMiddleLight: #c4c4c4;
$greyLight: #dedede;
$greyDisable: #8d8d8d;
$greyEmptyState: #819299;
$accent1: #36d1cf;
$accent2: #ba57ce;
$accent3: #4d8dcc;
$desaturatedAccent1: #aae4e4;
$desaturatedAccent2: #cc90d8;
$background1: #f7f8fd;
$Background2: #e9f5f5;
$mutedPrimary: #8a9ba8;
$menuBar: #0e1011;
$error: #fe5f55;
$warning: #f18b2d;
$white: #ffffff;
$blue1: #133556;
$blue2: #43678a;
$blue3: #7694b2;
$blue4: #d2d9df;
$blue5: #f4f7f9;
$blue6: #b3c0cd;
$yellow: #faad14;
$blue: #1890ff;
$green: #61ad7b;

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-secondaryLight,
.color-secondaryLight {
  color: $secondaryLight;
}

.text-darkGrey {
  color: $darkGrey;
}

.text-grey {
  color: $grey;
}

.text-greyMiddleLight {
  color: $greyMiddleLight;
}

.text-greyLight {
  color: $greyLight;
}

.text-greyDisable {
  color: $greyDisable;
}

.text-greyEmptyState {
  color: $greyEmptyState;
}

.text-accent1 {
  color: $accent1;
}

.text-accent2 {
  color: $accent2;
}

.text-accent3 {
  color: $accent3;
}

.text-desaturatedAccent1 {
  color: $desaturatedAccent1;
}

.text-desaturatedAccent2 {
  color: $desaturatedAccent2;
}

.text-background1 {
  color: $background1;
}

.text-Background2 {
  color: $Background2;
}

.text-mutedPrimary {
  color: $mutedPrimary;
}

.text-menuBar {
  color: $menuBar;
}

.text-error,
.color-error {
  color: $error;
}

.text-warning {
  color: $warning;
}

.text-white {
  color: $white;
}

.text-blue1 {
  color: $blue1;
}

.text-blue2 {
  color: $blue2;
}

.text-blue3 {
  color: $blue3;
}

.text-blue4,
.color-blue4 {
  color: $blue4;
}

.text-yellow {
  color: $yellow;
}

.text-blue {
  color: $blue;
}

.text-green,
.color-green {
  color: $green;
}

.text-warning,
.color-warning {
  color: $warning;
}

.border-accent3 {
  border-color: $accent3;
}

.border-error {
  border-color: $error;
}

.border-green {
  border-color: $green;
}

.bg-green {
  background-color: $green !important;
}
