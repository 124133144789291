.toolbar {
  border: 1px solid rgba(250, 250, 250, 0.2);
  background: #38424b;
  box-sizing: border-box;
}

.toolbar > div {
  display: flex;
}

.toolbar-separator {
  margin-top: 5px;
}

.toolbar:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #333;
  border-width: 4px;
  margin-left: -4px;
}

.toolbar:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #111;
  border-width: 6px;
  margin-left: -6px;
}
