.game-map-card {
  button.add-table-button {
    position: relative;
    top: 12px;
    background-color: #4e8dcd !important;
  }
}
.running-game-tab .ant-tabs-nav {
  width: 85%;
}
