.announcements-list-table
  .ant-spin-container
  .ant-table
  table
  .ant-table-tbody
  tr
  td:first-child {
  padding: 0;
}

.announcements-list-table
  .ant-spin-container
  .ant-table
  table
  .ant-table-thead
  tr
  th:first-child {
  width: 26px;
  padding-left: 0;
  padding-right: 10px;
  padding-bottom: 23px;
}

.dark-theme .announcements-list-table .ant-table-tbody > tr > td {
  vertical-align: inherit;
  height: 107px;
}

.announcements-list-table
  .ant-spin-container
  .ant-table
  table
  .ant-table-thead
  .ant-table-filter-column {
  justify-content: initial;
  .ant-table-column-title {
    flex: initial;
  }
}
