//Flop 2.0
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-loader-spinner/dist/loader/css/react-spinner-loader.css';
//@import 'flop-2.0/style/Button';
//@import 'flop-2.0/style/PageHeader';
//@import 'flop-2.0/style/Color';
//@import 'flop-2.0/style/Tabs';
//@import 'flop-2.0/style/Table';
//@import 'flop-2.0/style/Card';
//@import 'flop-2.0/style/Menu';
//@import 'flop-2.0/style/Form';
//@import 'flop-2.0/style/Modal';
//##############

@import 'custom.scss';

//###### Dark Theme ######
.dark-theme {
  color-scheme: dark;
}
.dark-theme .ant-popover-arrow-content::before {
  background: #434f5a !important;
}
.dark-theme .ant-popover-arrow {
  background-color: transparent !important;
}
.dark-theme .gx-layout-sider-scrollbar {
  height: calc(100vh - 72px - 170px - 50px) !important;
}

.dark-theme .ant-steps-item {
  &.ant-steps-item-finish {
    .ant-steps-item-title {
      color: #1dc997 !important;
    }
    .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: #1dc997 !important;
    }
  }
  &.ant-steps-item-process.ant-steps-item-active
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: #ffffff !important;
  }
  &.ant-steps-item-wait .ant-steps-item-title {
    color: #596977 !important;
  }
}

.dark-theme .ant-form-item-explain-error {
  color: #f5222d !important;
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #58636c !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #e0e0e0 !important;
}

.dark-theme .ant-btn.ant-btn-link {
  color: #038fde;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}

.dark-theme .ant-btn-dangerous.ant-btn-link {
  color: #f5222d;
}
.dark-theme .ant-empty-description {
  color: #ffffff;
}

.ant-table-thead > tr > th {
  font-weight: bold !important;
}

.gx-layout-sider-scrollbar > div:first-child {
  overflow-x: initial !important;
}

.ant-tabs-tabpane {
  //padding-right: 12px;
}

.dark-theme .gx-chat-main .gx-chat-list-scroll {
  height: calc(100vh - 407px) !important;
}

.dark-theme .gx-chat-main-content .gx-comment-box {
  height: calc(100vh - 464px) !important;
}

.dark-theme .gx-chat-item.gx-flex-row-reverse .gx-bubble {
  background-color: #e6faff !important;
  border: solid 1px #d9d9d9 !important;
  color: #545454 !important;
}

.dark-theme .gx-chat-item .gx-bubble {
  background-color: #ffffff !important;
  border: solid 1px #d9d9d9 !important;
  color: #545454 !important;
}

.dark-theme .gx-chat-list-scroll.scroll-modal {
  height: calc(100vh - 500px) !important;
}

.dark-theme .gx-chat-user-item:not(:last-child) {
  border-bottom-color: white !important;
}

.dark-theme .gx-chat-sidenav {
  border-right-color: white !important;
}

.dark-theme .gx-card.card-without-padding .ant-card-body {
  padding: 0 !important;
}

.dark-theme .gx-chat-sidenav-scroll-tab-1 {
  height: calc(100vh - 249px) !important;
}

.dark-theme .gx-chat-main-header {
  border-bottom-color: white !important;
}

.dark-theme .gx-chat-main-header.without-border {
  border: none;
}

.autocomplete-dropdown-container {
  background-color: #4e575e;
  color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 100;
  top: 60px;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.dark-theme .ant-badge-multiple-words {
  padding: 0 4px;
}

.dark-theme .ant-picker-cell-disabled > .ant-picker-cell-inner {
  background-color: #4e575e !important;
  opacity: 0.6;
}

.dark-theme .event-banner-upload .ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.dark-theme .gx-card .ant-card-cover {
  padding: 0 !important;
}

.dark-theme .ant-card-actions {
  background: #6c7177 !important;
  & > li {
    background: #6c7177 !important;
    color: #ffffff;
    & > span a:not(.ant-btn),
    & > span > .anticon {
      color: #ffffff !important;
    }
  }
}

.dark-theme .ant-switch-checked {
  background-color: #36d1cf !important;
}

.gx-user-wid {
  width: 150px !important;
  height: 150px !important;
}
.gx-user-wid img.dummy-image {
  height: 70px;
  width: 70px;
  position: initial;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin-left: 0 !important;
}

.gx-btn {
  font-size: 14px !important;
  font-weight: 600;
  color: #ffffff !important;
  background-color: #4a7a8a !important;
  border-color: #4a7a8a !important;
}

.dark-theme .ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}
.dark-theme .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  font-size: 14px;
  padding: 10px 0;
  &.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fa8c16 !important;
  }
}

.dark-theme .ant-collapse {
  background-color: transparent !important;
  border: none !important;
  .ant-collapse-item {
    border: none !important;
    .ant-collapse-content {
      background-color: transparent;
    }
  }
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -22.5px !important;
}

//###### END Dark Theme ######

a,
a:hover {
  text-decoration: none !important;
}

#root {
  height: 100%;
}

.main-sidebar__nav-title {
  text-transform: uppercase;
  margin: 0;
  font-size: 0.625rem;
  letter-spacing: 0.125rem;
  padding: 1px 1.5625rem;
  font-weight: 500;
  color: #9ea8b9;
  border-bottom: 1px solid #e1e5eb;
}

.main-sidebar .nav {
  border-bottom: 1px solid #e1e5eb;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hidden {
  visibility: hidden;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
}

.font12 {
  font-size: 12px !important;
}

.font10 {
  font-size: 10px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 18px !important;
}

.font20 {
  font-size: 20px !important;
}

.font24 {
  font-size: 24px !important;
}

.font32 {
  font-size: 32px !important;
}

.fontWeight600 {
  font-weight: 600 !important;
}

.fontWeightBold {
  font-weight: bold;
}

.vh75 {
  min-height: 75vh;
}

.vh100 {
  min-height: 100vh;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.padding-button {
  padding: 7px 30px !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.full-width-parent .ant-form-item-control-wrapper {
  width: 100%;
}

.input-grey input {
  background: rgba(0, 0, 0, 0.04);
  /* @disabled-color */
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.full-body-modal {
  .ant-modal-body {
    padding: 0;
  }
}

.modal-overflow {
  height: 60vh;
  overflow-y: auto;
}

.full-height-modal {
  height: calc(100vh - 250px);
}

.full-height-without-header-modal {
  .ant-modal-body {
    height: calc(100vh - 103px);
  }
}

@media only screen and (max-width: 1400px) {
  .full-height-modal {
    height: 60vh;
  }
}

.carousel {
  .slide {
    .legend {
      top: 50% !important;
      bottom: unset !important;
      background: rgba(255, 255, 255, 0.1) !important;
      border: 4px solid rgba(255, 255, 255, 0.4) !important;
      box-sizing: border-box !important;
      border-radius: 8px !important;
      font-weight: 600 !important;
      font-size: 26px !important;
      line-height: 39px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1 !important;
      width: 650px !important;
      left: 50% !important;
      margin-left: -325px !important;
      font-style: italic;
      height: 150px;
      margin-top: -75px;
    }
  }

  .control-dots {
    .dot.selected,
    .dot:hover {
      background: $primary;
    }
  }
}

.circle-upload .ant-upload.ant-upload-select-picture-card {
  border-radius: 50%;
  border: none;

  .ant-upload {
    padding: 0;
  }
}

.simple-upload .ant-upload.ant-upload-select {
  border: none;

  .ant-upload {
    padding: 0;
  }
}

.inside-label {
  border: 1px solid #c4c4c4;
  padding: 2px 5px !important;
  border-radius: 5px;

  label {
    color: #ccc !important;
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }

  input {
    padding: 2px !important;
    height: 15px !important;
    border: none !important;
  }
}

.small-input input {
  width: 60px;
}

.bg-danger td:hover {
  background: none !important;
}

.action-buttons-column {
  width: 200px;
}

.ant-pro-sider-menu-logo img {
  height: 64px !important;
}

.ant-spin-container {
  height: 100%;
}

@keyframes blinkMe {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.blinking-dealer-avatar {
  animation: blink-dealer-animation 2s infinite;
}

@keyframes blink-dealer-animation {
  50% {
    opacity: 0.5;
  }
}

.bold-numbers {
  list-style: none; /* Remove default numbering */
  counter-reset: li; /* Create a counter */
}

.bold-numbers > li {
  counter-increment: li; /* Increment the counter */
  position: relative; /* Create a positioning context */
}

.bold-numbers > li::before {
  content: counter(li) '. '; /* Use the counter as content */
  font-weight: bold; /* Make it bold */
  position: absolute; /* Position it absolutely */
  left: -37px;
  font-size: 20px;
  top: 1px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
