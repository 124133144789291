@import 'custom';

.table-page-wrapper {
  font-family: 'Roboto', sans-serif !important;
  height: 100%;
  .table-page-header {
    color: $secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .table-page-body {
    padding: 40px 20px 20px 20px;
  }
}
